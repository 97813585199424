import Styled from 'styled-components';

import { baseMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};

  .image-dri-wand {
    float: right;
    margin-left: 24px;
  }

  @media screen and (max-width: 500px) {
    .image-dri-wand {
      display: block;
      float: none;
      margin-left: 25%;
      width: 50%;
    }
  }
`;

export { Wrapper };
