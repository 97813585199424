import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/upholstery-cleaning';

import config from '../config';

export const query = graphql`
  query upholsteryCleaningPageQuery {
    toolImage: file(relativePath: { regex: "/dri-master-wands.jpg/" }) {
      childImageSharp {
        fixed(width: 188, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const UpholsteryCleaningPage = ({ data }) => (
  <Layout>
    <Wrapper>
      <h1 className="underlined">Upholstery Cleaning</h1>
      <p>
        Sci-Tech Upholstery Cleaning can clean almost any type of fiber,
        synthetics or natural. We carry a variety of effective cleaning
        procedures for upholstery. After testing the fabric for colorfastness,
        we will determine exactly how your piece should be cleaned. The fabric
        is pre-vacuumed, pre-treated, and rinsed with our very low moisture
        method (dry in 2 hrs). Our trucks carry a wide verity of
        pre-conditioners and the tools needed to perform the proper cleaning. No
        bragging – just fact – our technicians are some of the best upholstery
        cleaners in the state! Sci-Tech is even referred by other cleaners in
        the area that are leery to clean specialty fabrics such as Micro Fiber.
      </p>
      <p>
        We can also apply Bridgepoint’s Maxim Fine Fabric Protector to ensure
        your fine fabrics continue to have lasting beauty between cleanings.
      </p>
      <p>
        From heavily soiled to light cleaning, our cleaning process is designed
        to restore your furniture as close to its original condition as
        possible. We can clean all types of upholstery, even the most delicate
        of fabrics.
      </p>
      <h2>Amazing Dri-Steam, New Patented Technology!</h2>
      <h3 style={{ textTransform: 'none' }}>Dri-Steam Upholstery Tool</h3>
      <Img
        fixed={data.toolImage.childImageSharp.fixed}
        alt="Dri-Master Upholstery Tool"
        className="image-dri-wand"
      />
      <p>
        Our new upholstery cleaning tool is nothing short of amazing! High
        FDri-Master Wandlow/Low Moisture super-cleaning for fabrics that in the
        past could only be dry-cleaned is now a reality. Using a hypervelocity
        fluid stream, this new tool shears away soil without over-wetting or any
        overspray. High flow Jetless Cleaning Technology leaves the fabric
        cleaner and 50% drier.
      </p>
      <p>
        <strong>Your fabrics will be dry in 1-2 hours!</strong>
      </p>
      <p>For More Info Call Us Today!</p>
      <a href={config.phoneNumberHref}>{config.phoneNumber}</a>
    </Wrapper>
  </Layout>
);

export default UpholsteryCleaningPage;
